import cosmetics from "../assets/exp/cosmetics.jpg"
import rotations from "../assets/exp/itemrotation.jpg"
import PerArenaGenerator from "../assets/exp/perarenagen.png"
import MiniProjectCard from "./ui/MiniProjectCard";
import { default as blob } from "../assets/blob.svg";
import data from "../data";
import ProjectCard from "./ui/ProjectCard";

const Projects = () => {
  return (
    <div
      id="projects"
      className="projects mx-auto flex flex-col justify-center gap-12 p-4 text-center md:items-center md:justify-between lg:max-w-7xl"
    >
      <h1 className="py-10 text-3xl font-bold text-primary md:text-4xl ">
        Famous Projects
      </h1>
      {/* RECENT PROJECTS */}
      <ProjectCard
        title="BedWars Cosmeitcs"
        subtitle="SpigotMC Plugin (Java)"
        description="This is an addon for the supported bedwars plugin that adds various bedwars cosmetics to your server such as Sprays, Glyphs, Victory Dances, ShopKeeperSkins, Kill Messages, and much more! This plugin have been in development for 2+ years! Inspired by Hypixel Network."
        picture={cosmetics}
        github="https://github.com/IamTheDefender/BedWars-Cosmetics"
        website="https://www.spigotmc.org/resources/bedwars-cosmetics.106685/"
        tools={[]}
      />
      <ProjectCard
        title="Item Rotation"
        subtitle="SpigotMC Plugin (Java)"
        description="This is an addon for BedWars1058 (as of Feb. 2024) that adds a new shop category with special items such as unicorn item, ice bridge, and much more! This plugin was also inspired by Hypixel Network"
        picture={rotations}
        github="https://github.com/IamTheDefender/BedWars1058-ItemRotation"
        website="https://www.spigotmc.org/resources/item-rotation-bw1058.107016/"
        tools={[]}
        isImageOnLeft={true}
      />
      <ProjectCard
        title="PerArenaGenerator"
        subtitle="SpigotMC Plugin (Java)"
        description="This is yet another addon for BedWars1058 to add the ability to have multiple generator speed configuration per group instead of a global configuration, very lightweight."
        picture={PerArenaGenerator}
        website={"https://polymart.org/resource/bedwars1058-perarenagen.2815"}
        tools={[]}
      />
      {/* OTHER PROJECTS */}
      Checkout other projects in my GitHub, I did a lot of projects (50+) as a freelancer but, they cannot be listed here
      <img className="mx-auto w-1/2 md:w-1/6" src={blob} alt="blob" />
    </div>
  );
};

export default Projects;
