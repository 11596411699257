const SkillCard = ({ title, tools, children }) => {
  return (
    <div
      data-aos-easing="ease-in-out"
      data-aos="zoom-in"
      className="flex flex-col items-center rounded-2xl bg-darkPrimary py-6 px-4"
    >
      {children}
      <h2 className="pb-8 text-2xl font-bold text-secondary">{title}</h2>
      {/* <ReactTypingEffect
        speed="400"
        typingDelay="150"
        eraseDelay="6000"
        className="pb-8 text-2xl font-bold text-secondary"
        text={title}
      /> */}
      {/* ICON */}
      <div className="screen flex items-center justify-center flex flex-wrap justify-center gap-4">
      {tools.map((tool) => {
  if (typeof tool === 'string') {
    // If the tool is a string (text), render it with custom background color
    return (
      <div key={tool} className="bg-customColor text-white rounded-full p-2">
        {tool.text}
      </div>
    );
  } else {
    // Otherwise, assume it's an image and render it
    return <img key={tool.id} alt={tool.alt} src={tool.img} width={80} height={40}/>;
  }
})}

      </div>
    </div>
  );
};

export default SkillCard;
