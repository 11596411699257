import ExperienceCard from "./ui/ExperienceCard";
import { default as crazydevelopment } from "../assets/exp/crazydevelopment.png";
import { default as boroks } from "../assets/exp/borok's-development.webp";
import { default as novanexus } from "../assets/exp/novanexus.webp";
import { default as spideymc } from "../assets/exp/spigotmc.webp";



const Experience = () => {
  var crazyDevelopment = [
    "Founder & Creator of the development server, basically a hub for my public plugins.",
   "This was created to build a community and provide support for all my public plugins.",
  "Every seen 2022, this server has gained a lot of memebers and now as of Feb. 2024 sits at 300+ members."];

  var yu = [`Bachelor of science in Software Engineering`, `CGPA of: 4.0/4.0`];

  return (
    <div
      id="experience"
      className="mx-auto flex flex-col justify-center gap-8 p-4 text-center md:items-center  md:justify-between lg:max-w-7xl"
    >
      <h1 className="py-6 text-3xl font-bold text-primary md:text-4xl ">
        Experience
      </h1>

      {/* Experience Cards */}
      <ExperienceCard
        logo={crazydevelopment}
        name="Crazy Development"
        job="Founder"
        duration="July 2022 - Present"
        skills={crazyDevelopment}
      />
       <ExperienceCard
        logo={novanexus}
        name="Nova Nexus"
        job="Lead Developer"
        duration="Jan. 2024 - Present"
        skills={["Lead developer on the awesome minecraft server idea.", "I help with general server stuff as well as coding plugin(s) for the organization.", "This server is currently under-development, so no public info to mention."]}
      />
       <ExperienceCard
        logo={spideymc}
        name="SpideyMC"
        job="Founder & Developer"
        duration="June 2021 - June 2022"
        skills={["I was the Founder and the only Developer on this SMP project.", "I also coded custom plugins for it when it was open.", "The server was eventually closed due to lack of motivation and players."]}
      />
      

       <ExperienceCard
        logo={boroks}
        name="Boroks Development"
        job="Freelancer"
        duration="Nov. 2023 - Present"
        skills={["Freelancer in the organization","I take commissions from there as well. "]}
      />

    </div>
  );
};

export default Experience;
