import { default as blob } from "../assets/blob.svg";
import { IoLogoGithub } from "react-icons/io";
import { SiSpigotmc } from "react-icons/si";
import { FaSquareXTwitter } from "react-icons/fa6";
import TypeWriter from "./Typewriter";

const Hero = () => {
  var code = `public class Typing {
    public static void main(String[] args) {
        String text = "Hello, World! This is a typing effect in Java.";
        typing(text, 100);
    }

    public static void typing(String text, int delay) {
        char[] textArray = text.toCharArray();
        int index = 0;
        Thread thread = new Thread(() -> {
            try {
                while (index < textArray.length) {
                    System.out.print(textArray[index++]);
                    Thread.sleep(delay);
                }
            } catch (InterruptedException e) {
                e.printStackTrace();
            }
        });
        thread.start();
        try {
            thread.join();
        } catch (InterruptedException e) {
            e.printStackTrace();
        }
    }
}

`;
  return (
    <div
      id="about"
      className=" mx-auto	flex h-[120%] flex-col justify-center overflow-hidden px-4 py-1 text-center md:flex-row md:items-center md:justify-between md:text-left lg:max-w-7xl"
    >
      <div className="py-4 md:w-1/2 md:flex-col">
        <p className="inline py-1 text-5xl font-black text-primary md:text-7xl">
        </p>

        <h1 className="py-1 text-5xl font-black text-primary md:text-7xl"        >
        <TypeWriter
          delay={0}
          text="IamTheDefender"
        />
        </h1>
        <h2 className="py-1 text-3xl font-bold"> <TypeWriter text={"Java Developer"} delay={0} /></h2>
        <p className="py-1 text-xl md:w-3/4 flex justify-center items-centerflex justify-center items-center">
          <TypeWriter delay={80} text={"I'm a Java Developer mostly focused on creation of add-ons and plug-ins for " + 
          " Minecraft, I have expertise in Plugin development with various API's such as SpigotMC, PaperMC, Bungeecord, Velocity or more of their forks. I am a very passionate developer and give my best to every project."}></TypeWriter>
        </p>
        <div className="flex items-center justify-center gap-4 py-4 md:justify-start md:py-6">
          <a
            className="inline-flex items-center gap-2"
            href="https://github.com/IamTheDefender"
          >
            <IoLogoGithub className="text-2xl" />
          </a>
          <a
            className="inline-flex items-center gap-2"
            href="https://twitter.com/iamthedefender_"
          >
            <FaSquareXTwitter className="text-2xl" />
          </a>
          <a className="inline-flex items-center gap-2"
            href="https://www.spigotmc.org/members/kepler121.1531997/"
          >
            <SiSpigotmc className="text-2x1" />
          </a>
        </div>
      </div>
      <div className="md:w-1/2">
        <div className="relative whitespace-pre text-[.50rem] text-primary text-opacity-30 md:text-base">
          {code}
          <img
            className="absolute top-0 left-0 w-full "
            src={blob}
            alt="blob"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
