import { HiMail } from "react-icons/hi";
import { IoLogoGithub } from "react-icons/io";
import { BsLinkedin } from "react-icons/bs";
import { SiSpigotmc } from "react-icons/si";
import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <div
      id="contact"
      className="mx-auto flex flex-col justify-center gap-8 p-4 pb-8 text-center md:items-center md:justify-between lg:max-w-7xl"
    >
      <h1 className="py-6 text-3xl font-bold text-primary md:text-4xl ">
        Contact Me
      </h1>
      <div className="w-full md:flex md:flex-row md:justify-between md:gap-5 md:px-4">
        <a href="#">
        <h2 className="font-impact text-3xl">
              <span className="text-primary">I</span>am<span className="text-primary">T</span>he<span className="text-primary">D</span>efender
                <span className="text-primary"> </span>
              </h2>
        </a>
        <div className="flex flex-col items-center gap-4 py-4 md:flex-row md:py-0">
        <a
            className="inline-flex items-center gap-2"
            href="https://github.com/IamTheDefender"
          >
            <IoLogoGithub className="text-2xl" />
          </a>
          <a
            className="inline-flex items-center gap-2"
            href="https://twitter.com/iamthedefender_"
          >
            <FaSquareXTwitter className="text-2xl" />
          </a>
          <a className="inline-flex items-center gap-2"
            href="https://www.spigotmc.org/members/kepler121.1531997/"
          >
            <SiSpigotmc className="text-2x1" />
          </a>
          <a
            className="inline-flex items-center gap-2"
            href="mailto:contact@iamthedefender.xyz"
          >
            <HiMail /> contact@iamthedefender.xyz
          </a>
        </div>
      </div>
      <h1 className="py-2 text-xs font-bold text-primary">
        Made with ❤ by IamTheDefender
      </h1>
    </div>
  );
};

export default Footer;
